<template>
  <div class="section p-l-0 p-r-0">
    <div class="container is-fluid">
      <h1 class="is-size-3 has-text-primary has-text-weight-bold m-b-4x">
        User Management
      </h1>
      <el-form
        ref="localForm"
        :model="localForm"
        class="columns is-mini-gap is-multiline"
        status-icon
        @submit.native.prevent="">
        <el-form-item
          class="column is-narrow"
          prop="search">
          <el-input
            v-model="localForm.search"
            type="text"
            autocomplete="search"
            placeholder="search name, email, user ID"
            style="min-width: 320px;"
            @input="delaySearch" />
        </el-form-item>
        <el-form-item
          class="column is-narrow"
          prop="appCode">
          <el-select
            v-model="localForm.appCode"
            filterable
            clearable
            placeholder="Client name"
            @change="delaySearch">
            <el-option
              v-for="item in clientList"
              :key="item.appCode"
              :label="item.companyName"
              :value="item.appCode" />
          </el-select>
        </el-form-item>
        <div class="column is-narrow">
          <el-button
            v-if="hasSearchParams"
            class="button-secondary-outline m-r-3x"
            style="min-width: 100px;"
            @click="clear">
            CLEAR
          </el-button>
        </div>
        <div class="column has-text-right">
          <el-button
            type="primary"
            @click="exportModalActive=true">
            Export to excel
          </el-button>
          <el-button
            class="button-secondary"
            @click="inviteModalActive = true">
            Invite User
          </el-button>
        </div>
      </el-form>
      <el-table
        ref="singleTable"
        :data="items"
        stripe
        style="width: 100%"
        @sort-change="handleSortChanged"
        @row-click="handleSelected">
        <el-table-column
          property="id"
          label="#"
          sortable="custom"
          width="75" />
        <el-table-column
          sortable="custom"
          property="uid"
          label="User ID" />
        <el-table-column
          property="displayName"
          label="User" />
        <el-table-column
          property="email"
          label="Email" />
        <el-table-column
          property="roleName"
          label="Role" />
        <el-table-column label="Client name">
          <template slot-scope="scope">
            {{ scope.row.apps[0] ? scope.row.apps[0].label : '' }}
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <span :class="`${scope.row.inactive ? 'has-text-grey-light' : 'has-text-success'}`">{{ scope.row.inactive ? 'Suspended' : 'Active' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Invited at">
          <template slot-scope="scope">
            {{ getInvitedAt(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Joined at">
          <template slot-scope="scope">
            {{ getJoinedAt(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Actions">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.inactive"
              size="small"
              type="success"
              @click.stop="() => resendInvitationEmail(row)">
              Resend email
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total-items="totalItems"
        :page="localForm.page"
        :page-size="localForm.limit"
        :pager-count="5"
        top-element="#app"
        @change="handlePageChange" />

      <!-- Invite modal -->
      <PpModal v-model="inviteModalActive">
        <PpCard class="box has-border is-flex">
          <div class="edit-profile-modal-content modal-columns-outer">
            <el-form
              ref="inviteForm"
              :model="inviteForm"
              :rules="rules"
              class="columns is-multiline"
              label-position="top"
              status-icon>
              <div class="column is-12">
                <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                  {{ $t('views.MyAccount.Invite User') }}
                </h3>
              </div>
              <el-form-item
                :label="$t('views.customer.form.first-name.label')"
                class="column is-6"
                prop="firstName">
                <el-input
                  v-model="inviteForm.firstName"
                  :placeholder="$t('views.customer.form.first-name.placeholder')"
                  autocomplete="firstName" />
              </el-form-item>
              <el-form-item
                :label="$t('views.customer.form.last-name.label')"
                class="column is-6"
                prop="lastName">
                <el-input
                  v-model="inviteForm.lastName"
                  :placeholder="$t('views.customer.form.last-name.placeholder')"
                  autocomplete="lastName" />
              </el-form-item>
              <el-form-item
                :label="$t('views.customer.form.email.label')"
                class="column is-12"
                prop="email">
                <el-input
                  v-model.trim="inviteForm.email"
                  :placeholder="$t('views.customer.form.email.placeholder')"
                  type="email"
                  autocomplete="email" />
              </el-form-item>
              <el-form-item
                label="Role"
                class="column is-12"
                prop="roleCode">
                <InputUserRole
                  v-model="inviteForm.roleCode"
                  style="width: 100%;" />
              </el-form-item>
              <el-form-item
                v-if="['CLIENT_ADMIN', 'CLIENT_AGENT_S', 'CLIENT_AGENT_SB', 'CLIENT_AGENT_SBC', 'CLIENT_CS', 'CLIENT_FINANCE'].includes(inviteForm.roleCode)"
                label="Client"
                class="column is-12"
                prop="appCode">
                <el-select
                  v-model="inviteForm.appCode"
                  filterable
                  clearable
                  placeholder="Select one"
                  style="width: 100%">
                  <el-option
                    v-for="item in clientList"
                    :key="item.appCode"
                    :label="item.companyName"
                    :value="item.appCode" />
                </el-select>
              </el-form-item>
              <div
                v-if="errMsg"
                class="error-box">
                <el-alert
                  :title="errMsg"
                  :closable="false"
                  type="error" />
              </div>
              <div class="column is-12 has-text-right">
                <el-button
                  class="button-secondary-outline"
                  style="min-width: 115px;"
                  @click="handleCancel">
                  {{ $t('views.customer.form.cancel') }}
                </el-button>
                <el-button
                  :loading="isSubmitting"
                  class="button-secondary"
                  style="min-width: 170px;"
                  @click="handleInvite">
                  {{ $t('views.customer.form.Send Invitation') }}
                </el-button>
              </div>
            </el-form>
          </div>
        </PpCard>
      </PpModal>
      <!-- EXPORT MODAL -->
      <PpModal v-model="exportModalActive">
        <PpCard class="box has-border is-flex">
          <div class="export-modal-content">
            <div class="m-b-5x is-size-4 has-text-secondary has-text-weight-bold">
              {{ $t('views.MyBookings.Confirm to Export') }}
            </div>
            <div class="columns">
              <div class="column">
                <div class="has-text-grey-light">
                  Search
                </div>
                <div class="has-text-weight-bold">
                  {{ localForm.search || '-' }}
                </div>
              </div>
              <div class="column">
                <div class="has-text-grey-light">
                  Client name
                </div>
                <div class="has-text-weight-bold">
                  {{ searchedClientName ||'-' }}
                </div>
              </div>
            </div>
            <div
              style="margin-top: 60px;"
              class="has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="exportModalActive=false">
                {{ $t('views.MyBookings.Cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 155px;"
                @click="handleExport">
                {{ $t('views.MyBookings.Export') }}
              </el-button>
            </div>
          </div>
        </PpCard>
      </PpModal>
    </div>
  </div>
</template>

<script>
import { toSGT } from '@/plugins/dates'
import InputUserRole from '@/views/admin/components/InputUserRole'
import Pagination from '@/components/Pagination'
import debounce from 'lodash/debounce'
import { download } from '@/plugins/util'

export default {
  name: 'UserManagement',
  components: {
    InputUserRole,
    Pagination
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchData({ page: 1 })
      vm.fetchClientList()
    })
  },
  data () {
    return {
      exportModalActive: false,
      localForm: {
        search: undefined,
        appCode: undefined,
        page: 1,
        limit: 10,
        order: 'DESC',
        sortBy: 'id'
      },
      inviteForm: {},
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t('views.customer.form.first-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        roleCode: [
          {
            required: true,
            message: this.$t('views.customer.form.role-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        appCode: [
          {
            required: true,
            message: this.$t('views.customer.form.client.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      clientList: [],
      inviteModalActive: false,
      isSubmitting: false,
      errMsg: undefined,
      items: [],
      totalItems: 0
    }
  },
  computed: {
    searchedClientName () {
      if (!this.clientList.length || !this.localForm.appCode) return ''
      let result = this.clientList.find((item) => item.appCode === this.localForm.appCode)
      return result ? result.companyName : ''
    },
    hasSearchParams () {
      return !!this.localForm.search || !!this.localForm.appCode
    }
  },
  methods: {
    handleExport () {
      let store = this.$store
      store.dispatch('admin/exportUsersXLS', {
        ...this.localForm
      }).then(({
        data,
        headers
      }) => {
        this.exportModalActive = false
        download(data, headers)
      }).catch((err) => {
        if (err.meta) store.commit('admin/SET_META', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    getInvitedAt (user) {
      const invitationHistory = user.history.find(u => u.type === 'INVITATION_ISSUED')
      if (!invitationHistory) return ''
      return toSGT(invitationHistory.createdAt)
    },
    getJoinedAt (user) {
      const invitationHistory = user.history.find(u => u.type === 'INVITATION_ACCEPTED')
      if (!invitationHistory) return toSGT(user.createdAt)
      return toSGT(invitationHistory.createdAt)
    },
    fetchClientList () {
      this.$store.dispatch('admin/clientList', { page: 1 }).then(
        ({ outlets }) => {
          this.clientList = outlets.items
        }
      )
    },
    fetchData (params = null) {
      console.log('fetching user')
      this.$store.dispatch('admin/userList', {
        ...params
      }).then(
        ({ outlets, meta }) => {
          this.items = outlets.items
          this.totalItems = outlets.totalItems
        }
      )
    },
    handleSelected (row, column) {
      // This is disabled because when trying to copy the email
      // the page would be redirected to the user page
      if (column.property === 'email' || column.property === 'displayName') {
        return
      }

      const uid = row.uid
      this.$router.push({
        name: 'admin/user-management/overview',
        params: { uid }
      })
    },
    handleSubmit (e) {
      if (e) e.preventDefault()
      this.localForm.page = 1
      this.fetchData(this.localForm)
    },
    handleInvite () {
      this.errMsg = null
      this.$refs.inviteForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          let payload = { ...this.inviteForm }
          if (['SUPER_ADMIN', 'CS_ADMIN', 'FINANCE_ADMIN'].includes(this.inviteForm.roleCode)) {
            delete payload.appCode
          }
          this.$store.dispatch('admin/inviteUser', payload).then(
            (resp) => {
              this.isSubmitting = false
              this.inviteModalActive = false
              this.resetForm()
              this.fetchData(this.localForm)
            }
          ).catch((err) => {
            this.isSubmitting = false
            this.errMsg = err.message || (err.error && err.error.message) || ''
          })
        }
      })
    },
    resetForm () {
      this.inviteForm.email = undefined
      this.inviteForm.firstName = undefined
      this.inviteForm.lastName = undefined
      this.inviteForm.roleCode = undefined
    },
    handleCancel () {
      this.$refs.inviteForm.resetFields()
      this.inviteModalActive = false
    },
    handlePageChange ({ page, limit }) {
      this.localForm.page = page
      this.localForm.limit = limit
      this.fetchData(this.localForm)
    },
    delaySearch: debounce(function () {
      this.handleSubmit()
    }, 500),
    clear () {
      this.localForm = {
        search: undefined,
        appCode: undefined,
        page: 1,
        limit: 10
      }
      this.handleSubmit()
    },
    handleSortChanged ({ order, prop }) {
      const sortOrder = {
        ascending: 'ASC',
        descending: 'DESC'
      }
      this.localForm.sortBy = prop
      this.localForm.order = sortOrder[order]
      this.fetchData(this.localForm)
    },
    resendInvitationEmail (row) {
      this.$confirm('Do you want to resend confirmation email ?')
        .then(() => {
          this.$store.commit('SHOW_FULLSCREEN_LOADER')
          this.$store.dispatch('admin/resendUserInvitation', { email: row.email }).then((resp) => {
            this.localForm.page = 1
            this.fetchData(this.localForm)
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
          }).catch((err) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.$store.commit('SET_GLOBAL_MODAL', {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            })
          })
        })
    }
  },
  metaInfo () {
    return {
      title: 'User Management'
    }
  }
}
</script>
