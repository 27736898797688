import moment from 'moment'
import momentTz from 'moment-timezone'

// End of date
const endOfDay = (date) => {
  try {
    return moment.utc(date).endOf('day')
  } catch (err) {
    throw err
  }
}

const startOfDay = (date) => {
  try {
    return moment.utc(date).startOf('day')
  } catch (err) {
    throw err
  }
}

const fullDayRange = (range) => {
  if (!Array.isArray(range)) throw new TypeError('Invalid range, range must be an Array with date object')
  return [startOfDay(range[0]), endOfDay(range[1])]
}

const dateDiff = (start, end, diff = 'days') => {
  return moment.utc(end).diff(moment.utc(start), diff)
}

export const subtract = (date, num, type = 'd') => {
  return moment(date).subtract(type, num)
}

export const add = (date, num, type = 'd') => {
  return moment(date).add(type, num)
}

export const toSGT = (date, format = 'MMM, DD YYYY HH:mm') => {
  return momentTz(date).tz('Asia/Singapore').format(format)
}

export const isAfter = (date, unit = 'd') => {
  return moment().isAfter(date, unit)
}

export const isRefundable = (freeCancellationBefore) => {
  if (!freeCancellationBefore) return false

  const today = moment().utc()
  // const diff = moment(freeCancellationBefore).utc().diff(today, 'd');

  return today.isBefore(moment.utc(freeCancellationBefore), 'h')
}

export const formatUTC = (date, format = 'MMM, DD YYYY HH:mm') => {
  return moment(date).utc().format(format)
}

export default {
  endOfDay,
  startOfDay,
  fullDayRange,
  dateDiff,
  subtract,
  add,
  toSGT,
  isAfter,
  isRefundable,
  formatUTC
}
