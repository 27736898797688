export default [
  {
    value: 'CLIENT_ADMIN',
    label: 'Company Admin'
  },
  // {
  //   value: 'CLIENT_AGENT_S',
  //   label: 'Agent (search)'
  // },
  {
    value: 'CLIENT_AGENT_SB',
    label: 'ITC (search, book, cancel own bookings)'
  },
  {
    value: 'CLIENT_AGENT_SBC',
    label: 'Agent (search, book, cancel)'
  },
  // {
  //   value: 'CLIENT_CS',
  //   label: 'Customer Service'
  // },
  {
    value: 'CLIENT_FINANCE',
    label: 'Finance'
  },
  {
    value: 'CS_ADMIN',
    label: 'eBEDS Customer Service'
  },
  {
    value: 'FINANCE_ADMIN',
    label: 'eBEDS Finance'
  },
  {
    value: 'SUPER_ADMIN',
    label: 'eBEDS Super Admin'
  }
]
