<template>
  <AdminSubMenu
    :menu="menu"
    :sub-menu="subMenu">
    <slot />
  </AdminSubMenu>
</template>

<script>
import AdminSubMenu from '@/views/admin/AdminSubMenu'

export default {
  name: 'UserManagementSubMenu',
  components: { AdminSubMenu },
  props: {
    userRole: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menu: {
        name: 'admin/user-management'
      },
      subMenu: [
        {
          name: 'admin/user-management/overview',
          title: this.$t('views.admin.Overview')
        },
        {
          name: 'admin/user-management/history',
          title: this.$t('views.admin.History')
        }
      ]
    }
  },
  created () {
    if (!['SUPER_ADMIN', 'CS_ADMIN', 'FINANCE_ADMIN'].includes(this.userRole)) {
      this.subMenu.push({
        name: 'admin/user-management/client',
        title: 'Client'
      })
    }
  }
}
</script>

<style scoped>

</style>
