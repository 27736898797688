<template>
  <path d="M2.27656 10C2.30781 10 2.33906 9.99687 2.37031 9.99219L4.99844 9.53125C5.02969 9.525 5.05938 9.51094 5.08125 9.4875L11.7047 2.86406C11.7192 2.84961 11.7307 2.83244 11.7385 2.81354C11.7463 2.79463 11.7504 2.77437 11.7504 2.75391C11.7504 2.73344 11.7463 2.71318 11.7385 2.69428C11.7307 2.67538 11.7192 2.65821 11.7047 2.64375L9.10781 0.0453125C9.07812 0.015625 9.03906 0 8.99687 0C8.95469 0 8.91563 0.015625 8.88594 0.0453125L2.2625 6.66875C2.23906 6.69219 2.225 6.72031 2.21875 6.75156L1.75781 9.37969C1.74261 9.46339 1.74804 9.54954 1.77364 9.63067C1.79923 9.71181 1.84421 9.78548 1.90469 9.84531C2.00781 9.94531 2.1375 10 2.27656 10ZM3.32969 7.275L8.99687 1.60938L10.1422 2.75469L4.475 8.42031L3.08594 8.66562L3.32969 7.275ZM12 11.3125H0.5C0.223437 11.3125 0 11.5359 0 11.8125V12.375C0 12.4438 0.05625 12.5 0.125 12.5H12.375C12.4438 12.5 12.5 12.4438 12.5 12.375V11.8125C12.5 11.5359 12.2766 11.3125 12 11.3125Z" />
</template>

<script>
export default {
  name: 'IconEdit'
}
</script>

<style scoped>

</style>
