<template>
  <el-select
    :value="local"
    autocomplete="off"
    placeholder="Select one"
    @change="handleChange">
    <el-option
      v-for="item in roleList"
      :key="item.value"
      :label="item.label"
      :value="item.value" />
  </el-select>
</template>

<script>
import { retrieveAdmin } from '@/service/auth'
import roleList from '@/views/admin/roleList'

export default {
  name: 'InputUserRole',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    local: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', this.local)
      }
    },
    roleList () {
      switch (retrieveAdmin('role')) {
      case 'FINANCE_ADMIN':
      case 'CS_ADMIN':
        return roleList.filter(r => !['SUPER_ADMIN', 'CS_ADMIN', 'FINANCE_ADMIN'].includes(r.value))
      default: return roleList
      }
    }
  },
  methods: {
    handleChange (nVal) {
      this.$emit('input', nVal)
    }
  }
}
</script>

<style scoped>

</style>
