import roleList from '@/views/admin/roleList'
export default {
  data () {
    return {
      isFetchingUser: false,
      uid: '',
      isSubmitting: false,
      isFetching: false,
      user: undefined,
      userApps: [],
      userForm: undefined,
      histories: []
    }
  },
  created () {
    this.fetchUser()
  },
  filters: {
    roleLabel (value) {
      const role = roleList.find(r => r.value === value)
      return role ? role.label : ''
    }
  },
  methods: {
    fetchUser () {
      this.isFetchingUser = true
      this.$store.dispatch('admin/userOne', this.$route.params.uid)
        .then(userResp => {
          if (userResp.outlets && userResp.outlets.details) {
            const user = userResp.outlets.details
            this.user = user
            this.userApps = user.apps
            if (this.userApps.length) {
              this.userApps = this.userApps.sort((a, b) => a.label.localeCompare(b.label))
            }
            this.userForm = {
              firstName: user.firstName,
              lastName: user.lastName,
              roleCode: user.roleCode,
              email: user.email
            }
            this.uid = user.uid
            this.$store.dispatch('admin/userHistory', this.$route.params.uid)
              .then(historyResp => {
                this.isFetchingUser = false
                this.histories = historyResp.outlets.details.history.reverse()
              })
          }
        })
    }
  }
}
