<template>
  <div>
    <UserManagementSubMenu
      v-if="!isFetchingUser"
      :user-role="user.roleCode">
      {{ user.displayName }}
    </UserManagementSubMenu>
    <div
      v-if="!isFetchingUser"
      class="section">
      <div class="container">
        <div class="columns is-large-gap">
          <div class="column is-6">
            <h2 class="is-size-4 has-text-weight-bold has-text-secondary m-b-3x">
              User details
            </h2>
            <div class="columns is-gapless columns-table m-b-0">
              <div class="column">
                <div class="th">
                  User Info
                </div>
              </div>
              <div class="column has-text-right">
                <div class="th">
                  <a
                    class="has-text-white"
                    @click.prevent="toggleEditModal">
                    <IconBase
                      width="13"
                      height="13"
                      icon-name="edit">
                      <IconEdit />
                    </IconBase>
                    {{ $t('views.MyAccount.Edit') }}</a>
                </div>
              </div>
            </div>
            <div class="columns is-multiline is-gapless columns-table m-b-0">
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.first-name.label') }}
                  </div>
                  <div>{{ user.firstName }}</div>
                </div>
              </div>
              <div class="column  is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.last-name.label') }}
                  </div>
                  <div>{{ user.lastName }}</div>
                </div>
              </div>
              <div class="column  is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.email.label') }}
                  </div>
                  <div class="has-text-break-word">
                    {{ user.email }}
                  </div>
                </div>
              </div>
              <div class="column  is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    Role
                  </div>
                  <div>{{ user.roleCode | roleLabel }}</div>
                </div>
              </div>
              <div class="column  is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    Invited by
                  </div>
                  <div>{{ user.invitedBy || 'self registered' }}</div>
                </div>
              </div>
              <div class="column  is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    Joined at
                  </div>
                  <div>{{ user.createdAt | date('DD MMM YYYY, HH:MM:SS') }}</div>
                </div>
              </div>
              <div class="column  is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    User ID
                  </div>
                  <div>{{ user.uid }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <h2 class="is-size-4 has-text-weight-bold has-text-secondary m-b-3x">
              {{ $t('views.admin.Permission') }}
            </h2>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  User status
                </div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="status" />
              </div>
            </div>
            <hr>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-danger">
                  Delete user
                </div>
                <div>Once deleted, you cannot recover this user. Please be certain.</div>
              </div>
              <div class="column is-narrow">
                <el-button
                  type="danger"
                  @click="deleteUserModalActive=true">
                  Delete
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit modals -->
    <PpModal v-model="editModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="userForm"
            :model="userForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit profile') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.first-name.label')"
              class="column is-6"
              prop="firstName">
              <el-input
                v-model="userForm.firstName"
                :placeholder="$t('views.customer.form.first-name.placeholder')"
                autocomplete="firstName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.last-name.label')"
              class="column is-6"
              prop="lastName">
              <el-input
                v-model="userForm.lastName"
                :placeholder="$t('views.customer.form.last-name.placeholder')"
                autocomplete="lastName" />
            </el-form-item>
            <el-form-item
              label="Role"
              class="column is-12"
              prop="roleCode">
              <InputUserRole
                v-model.trim="userForm.roleCode"
                style="width: 100%;" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.email.label')"
              class="column is-12"
              prop="email">
              <el-input
                v-model.trim="userForm.email"
                :placeholder="$t('views.customer.form.email.placeholder')"
                autocomplete="email" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSave">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <DeleteUser
      :open.sync="deleteUserModalActive"
      :user="user" />
  </div>
</template>

<script>
import UserManagementSubMenu from '@/views/admin/UserManagementSubMenu'
import IconEdit from '@/components/icons/IconEdit'
import selectedUser from './mixins/selectedUser'
import InputUserRole from '@/views/admin/components/InputUserRole'
import DeleteUser from './components/DeleteUser'

export default {
  name: 'UserManagementOverview',
  components: {
    InputUserRole,
    UserManagementSubMenu,
    IconEdit,
    DeleteUser
  },
  mixins: [selectedUser],
  data () {
    return {
      deleteUserModalActive: false,
      editModalActive: false,
      userForm: {
        email: '',
        firstName: '',
        lastName: '',
        roleCode: ''
      },
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t('views.customer.form.first-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        roleCode: [
          {
            required: true,
            message: 'Role is required.',
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change',
              'blur',
              'input'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ]
      }
    }
  },
  computed: {
    status: {
      get () {
        return !this.user.inactive
      },
      set (val) {
        this.user.inactive = !val
        this.userForm.inactive = !val

        this.$store.dispatch('admin/updateUser', {
          uid: this.uid,
          inactive: !val
        })
      }
    }
  },
  methods: {
    handleSave () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('admin/updateUser', {
            uid: this.uid,
            ...this.userForm
          }).then((_) => {
            this.fetchUser()
            this.editModalActive = false
          }).catch((err) => {
            this.fetchUser()
            if (err.error) {
              this.$message({
                message: err.error.message,
                type: 'error'
              })
            } else {
              this.$message({
                message: err.message,
                type: 'error'
              })
            }
          })
        }
      })
    },
    handleCancel () {
      this.$refs.userForm.resetFields()
      this.editModalActive = false
    },
    toggleEditModal () {
      this.editModalActive = true
    }
  },
  metaInfo () {
    const title = this.user ? `${this.user.displayName}'s Overview` : ''
    return {
      title: title
    }
  }
}
</script>

<style scoped>

</style>
