<template>
  <div>
    <UserManagementSubMenu
      v-if="!isFetchingUser"
      :user-role="user.roleCode">
      {{ user.displayName }}
    </UserManagementSubMenu>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="has-text-weight-bold is-size-4 has-text-secondary">
              Clients
            </h2>
          </div>
          <div class="column is-narrow">
            <el-button
              v-if="false"
              class="button-secondary"
              style="min-width: 140px;"
              @click="addClientModalActive=true">
              Add Client
            </el-button>
          </div>
        </div>
        <el-table
          ref="clientsTable"
          :data="userApps"
          stripe
          style="width: 100%"
          @row-click="handleSelected">
          <el-table-column
            type="index"
            width="50" />
          <el-table-column label="Client name">
            <template slot-scope="scope">
              {{ scope.row.label }}
            </template>
          </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="scope">
              <el-switch
                :value="!scope.row.inactive"
                @click.native.stop
                @change="handleStatusChange($event, scope.row)" />
            </template>
          </el-table-column>
          <!--<el-table-column width="50">
            <template slot-scope="scope">
              <el-link
                icon="el-icon-delete"
                type="danger"
                @click="removeUser(scope.row)"/>
            </template>
          </el-table-column>-->
        </el-table>
      </div>
    </div>
    <PpModal v-model="addClientModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="addClientForm"
            :model="addClientForm"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                Add client
              </h3>
            </div>
            <div class="column is-12">
              Please enter the client name below.
            </div>
            <el-form-item
              label="Client name"
              class="column is-12 m-b-5x"
              prop="displayName">
              <el-autocomplete
                v-model.trim="addClientForm.displayName"
                :fetch-suggestions="handleFetchSuggestions"
                :trigger-on-focus="false"
                :debounce="600"
                :loading="isFetchingSuggestion"
                select-when-unmatched
                style="width: 100%;"
                value-key="displayName"
                @select="handleUserSuggestSelect">
                <template slot-scope="{ item }">
                  <div
                    v-if="item.type === 'create'"
                    class="list is-narrow">
                    <div class="list-content">
                      <div class="list-label">
                        No client found.
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="list is-narrow">
                    <div class="list-content">
                      <div class="list-value">
                        {{ item.displayName || '–' }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                :disabled="!autoClients.length || !suggestSelected"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSave">
                Add Client
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>

import UserManagementSubMenu from '@/views/admin/UserManagementSubMenu'
import selectedUser from './mixins/selectedUser'

export default {
  name: 'UserManagementClient',
  components: {
    UserManagementSubMenu
  },
  mixins: [selectedUser],
  data () {
    return {
      addClientModalActive: false,
      addClientForm: {
        displayName: undefined
      },
      clients: [],
      autoClients: [],
      isFetchingSuggestion: false,
      isFetching: false,
      suggestSelected: undefined
    }
  },
  methods: {
    handleSelected (row) {
      const appCode = row.appCode
      this.$router.push({ name: 'admin/client-management/overview', params: { appCode } })
    },
    handleSave () {
      this.$refs.addClientForm.validate((valid) => {
        if (valid) {
          const payload = {
            appCode: this.company.appCode,
            inactive: false,
            ...this.suggestSelected
          }
          this.$store.dispatch('admin/clientUser', payload).then(
            ({ outlets }) => {
              if (outlets.ok) {
                this.addClientModalActive = false
                this.fetchData()
              }
            }
          )
        }
      })
    },
    handleCancel () {
      this.$refs.addClientForm.resetFields()
      this.suggestSelected = undefined
      this.addClientModalActive = false
    },
    removeUser (user) {
      this.$confirm(this.$t('views.MyAccount.delete-user-message'), this.$t('views.MyAccount.delete-user-title'), {
        confirmButtonText: this.$t('views.customer.form.submit'),
        cancelButtonText: this.$t('views.customer.form.cancel'),
        cancelButtonClass: 'button-secondary-outline',
        confirmButtonClass: 'button-secondary',
        customClass: 'warning-message-box',
        dangerouslyUseHTMLString: true
      }).then(() => {
        const payload = {
          uid: this.uid,
          delete: true
        }
        this.$store.dispatch('admin/updateUser', payload).then(
          ({ outlets }) => {
            if (outlets.ok) {
              this.$router.push({ name: 'admin/user-management' })
            }
          }
        )
      }).catch(() => {
        // todo
      })
    },
    handleFetchSuggestions (queryString, cb) {
      if (queryString !== '' && queryString.length > 2) {
        this.isFetchingSuggestion = true
        this.$store.dispatch('admin/clientAutosuggest', { q: queryString.toLowerCase().trim() }).then(({ outlets }) => {
          this.autoClients = outlets.items.filter((obj) => !this.clients.map((user) => user.uid).includes(obj.uid))
          this.isFetchingSuggestion = false
          if (this.autoClients.length > 0) {
            cb(this.autoClients)
          } else {
            const list = [
              {
                email: queryString,
                type: 'create'
              }
            ]
            cb(list)
          }
        }).catch((err) => {
          this.isFetchingSuggestion = false
          console.log(err)
        })
      }
    },
    handleUserSuggestSelect (item) {
      this.suggestSelected = item
    },
    handleStatusChange (e, row) {
      if (e) {
        const activeApp = this.userApps.find((app) => !app.inactive)
        if (activeApp) {
          return this.$message({
            message: 'Multiple active client not supported',
            type: 'error'
          })
        }
      }
      row.inactive = !e
      const payload = {
        uid: this.uid,
        inactive: row.inactive,
        appCode: row.appCode
      }
      this.$store.dispatch('admin/updateUserClient', payload).then(
        ({ outlets }) => {
          if (outlets.ok) {
            this.fetchUser()
          }
        }
      ).catch((err) => {
        if (err.error) {
          row.inactive = e
          this.$message({
            message: err.error.message,
            type: 'error'
          })
        }
      })
    }
  },
  metaInfo () {
    const title = this.user ? `${this.user.displayName}'s Clients` : ''
    return {
      title: title
    }
  }

}
</script>

<style scoped>

</style>
